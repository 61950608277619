import "./Navigation.scss"

function Navigation() {
    return (
        <nav className="nav">
            <span className="nav__logo">
                <span className="nav__logo-red">Sale</span> Discount Calculator
            </span>
            <span className="nav__slogan">The original discount calculator!</span>
        </nav>
    )
}

export default Navigation;
