import React, { useState, useEffect } from 'react';
import './Calculator.scss';
import currencies from './../constants/currencies';
import { ReactComponent as GearIcon } from './../assets/gear-svgrepo-com.svg';
import Switch from '@mui/joy/Switch';
import Tooltip from '@mui/joy/Tooltip';
import Slider from '@mui/joy/Slider';
import Drawer from '@mui/joy/Drawer';

function Calculator() {
  const [price, setPrice] = useState(0.00);
  const [discount, setDiscount] = useState(0);
  const [additionalDiscount, setAdditionalDiscount] = useState(0);
  const [tax, setTax] = useState(0);
  const [incrementValue, setIncrementValue] = useState(1);
  const [currency, setCurrency] = useState("$");
  const [modal, setModal] = useState(false);
  const [includeTaxRate, setIncludeTaxRate] = useState(false);

  const calculateFinalPrice = () => {
    if (isNaN(price)) {return '0.00'}
    const discountedPrice = price - (price * discount) / 100;
    const furtherDiscount = discountedPrice - (discountedPrice * additionalDiscount) / 100;
    const taxAmount = includeTaxRate ? (furtherDiscount * tax) / 100 : 0;
    const result = (furtherDiscount + taxAmount).toFixed(2);
    return isNaN(result) ? '0.00' : result;
  };

  const calculateSavings = () => {
    if (isNaN(price)) {return '0.00'}
    const result = (price - calculateFinalPrice()).toFixed(2);
    return isNaN(result) ? '0.00' : result;
  };

  const priceOnFocus = () => {
    return price === 0 ? setPrice('') : price;
  }

  const twoDecimalPlaces = (num) => Math.round(num * 100) / 100;
  const ls = window.localStorage;
  const setLocalStorage = (cur) => ls.setItem("currency", cur);

  useEffect(() => {
    const cur = ls.getItem('currency');
    if (cur) {
      setCurrency(cur);
    }
  // eslint-disable-next-line
  }, []);

  return (
    <div className="calculator">
    <Drawer
        anchor="right"
        open={modal}
        onClose={() => setModal(false)}
      >
        <div className="tw-p-4">
          <label>Change currency: &nbsp;&nbsp;</label>
          <select 
            className="dropdown" 
            onChange={(e) => {setCurrency(e.target.value); setLocalStorage(e.target.value);}}  
            value={currency}
          >
            {
              Object.keys(currencies).map((option) => 
                <option 
                  key={option} 
                  value={currencies[option].symbol}
                >
                    {currencies[option].symbol}
                </option>
              )
            }
          </select>
        </div>
      </Drawer>

      <div className="calculator__wrapper">
        <div className="settingsWrapper">
          <button className="settingsButton">
            <GearIcon className="gearIcon" onClick={() => setModal(true)}/>
          </button>
        </div>

        <div className="sale-tag">
          <h2 className="roboto-black">
            SALE PRICE 
            <br/> 
            <span className="roboto-medium">
              {currency} {calculateFinalPrice()}
            </span>
          </h2>
          <p>
            you save {currency} {calculateSavings()}
          </p>
        </div>

        <div className="controls">
          <div className="controls__apart">
            <div>
              <label>Price:</label>
              <span className="prepend-dollar">{currency}</span>
              <input
                className="dollar-value"
                type="number"
                inputMode="decimal"
                min={0}
                value={price}
                onChange={(e) => setPrice(twoDecimalPlaces(parseFloat(e.target.value)))}
                onFocus={() => priceOnFocus()}
              />
            </div>
            <div>
              <Tooltip title={`Increase slider by ${incrementValue} ${incrementValue > 1 ? 'units' : 'unit'}`} variant="solid">
                <button 
                  className="incrementButton"
                  onClick={e => setIncrementValue(incrementValue === 1 ? 5 : 1)}>
                    ↑ {incrementValue}%
                </button>
              </Tooltip>
            </div>
          </div>

          <div className="discounts">
            <label>Discount: {discount}%</label>
            <Slider
              color="danger"
              disabled={false}
              marks={false}
              orientation="horizontal"
              size="md"
              variant="solid"
              min={0}
              max={100}
              step={incrementValue}
              value={discount}
              onChange={(e) => setDiscount(parseInt(e.target.value))}
            />
          </div>

          <div className="discounts">
            <label>Additional Discount: {additionalDiscount}%</label>
            <Slider
              color="danger"
              disabled={false}
              marks={false}
              orientation="horizontal"
              size="md"
              variant="solid"
              min={0}
              max={100}
              step={incrementValue}
              value={additionalDiscount}
              onChange={(e) => setAdditionalDiscount(parseInt(e.target.value))}
            />
          </div>

          <div className="taxrate">
            <div>
              <span className="text-small">Include tax? </span>
              <Switch 
                className="toggleswitch"
                color={includeTaxRate ? 'danger' : 'neutral'} 
                checked={includeTaxRate}
                onChange={(evt) =>
                  setIncludeTaxRate(evt.target.checked)  
                }
              />
            </div>

            {
              includeTaxRate && (
                <div className="taxrateinput">
                  <label>Tax rate:</label>
                  <input
                    type="number"
                    inputMode="decimal"
                    value={tax}
                    max={100}
                    onChange={(e) => setTax(twoDecimalPlaces(parseFloat(e.target.value)))}
                    onFocus={() => tax === 0 ? setTax('') : tax}
                  />
                  <span className="appendicon">%</span>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
    )
}

export default Calculator;
