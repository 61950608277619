import "./SeoBlurb.scss"

function SeoBlurb() {
    return (
        <aside className="seo-blurb">
          <h1 className="seo-blurb__title">
            Discount Calculator
          </h1>
          <p>
            Welcome to your go-to Sale Discount Calculator! Simplify your shopping experience by quickly calculating sale prices with multiple discount options and tax rates. Whether you're shopping deals or managing retail sales, our tool helps you see your savings instantly. Just enter the base price, select discounts, add taxes, and get the final price without the hassle. Save time, save money—start calculating smarter today!
          </p>

          <h2 className="seo-blurb__title tw-mt-8">
            How to Use the Discount Calculator:
          </h2>
          <ol className="seo-blurb__list">
            <li>
              <strong>Enter the Base Price:</strong> Start by entering the original price of the item.
            </li>
            <li>
              <strong>Set the Primary Discount:</strong> Use the discount slider or input box to choose the main discount percentage (e.g., 40% off).
            </li>
            <li>
              <strong>Apply Additional Discount (if any):</strong> If there's a second discount, select the percentage to apply on the already-discounted price.
            </li>
            <li>
              <strong>Enter Tax Percentage:</strong> Add any applicable tax percentage to see the final price, including tax.
            </li>
            <li>
              <strong>Get Your Final Price:</strong> The calculator will display the final sale price after all discounts and tax, along with the amount you save.
            </li>
          </ol>

          <h2 className="seo-blurb__title tw-mt-8" >Discount Calculation Formula:</h2>

          <ol className="seo-blurb__list">
            <li>
              Calculate First Discount:
              <code className="math-formula">
                Discounted Price = Base Price − (Base Price × Primary Discount/100)
              </code>
            </li>
            
            <li>
              Apply Additional Discount:
              <code className="math-formula">
                Additional Discounted Price = Discounted Price − (Discounted Price × Additional Discount/100)
              </code>
            </li>

            <li>
              Calculate Tax:
              <code className="math-formula">
                Final Price = Additional Discounted Price + (Additional Discounted Price × Tax/100)
              </code>
            </li>

            <li>
              Savings:
              <code className="math-formula">
                Savings = Base Price − Final Price
              </code>
            </li>
          </ol>

          <p>
            This formula ensures you get an accurate final price after discounts and tax, helping you understand the real cost and savings!
          </p>
        </aside>
    )
}

export default SeoBlurb;