import './App.scss';
import Navigation from './components/Navigation';
import Calculator from './components/Calculator';
import SeoBlurb from './components/SeoBlurb';

function App() {
  const year = new Date().getFullYear();
  
  return (
    <div className="app">
      <Navigation />

      <aside>
        <div className="ad">
          {/* INSERT AD */}
        </div>
      </aside>

      <main className="tw-flex tw-flex-col-reverse md:tw-flex-row tw-gap-4 tw-items-start">
        <SeoBlurb />
        <Calculator />
      </main>

      <article>
        {/* AD */}
      </article>

      <footer>Copyright {year} - Sale Discount Calculator. All rights reserved.</footer>
    </div>
  );
}

export default App;
